
import { defineComponent, ref, onMounted } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import TerritoryModal from "@/components/modals/forms/TerritoryModal.vue";
import TerritoryViewModal from "@/components/modals/forms/TerritoryViewModal.vue";
// import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import {
  ITerritoryDto,
  IGetTerritoryForViewDto,
} from "@/shared/service-proxies/service-proxies";
import { TerritoriesFilter } from "@/models";
import AJLoader from "@/components/AjaxLoader.vue";
import useTerritories from "@/composables/useTerritories";
import DeleteItemService from "@/core/services/DeleteItemService";
import { useStore } from "vuex";

export default defineComponent({
  components: {
    Datatable,
    TerritoryModal,
    TerritoryViewModal,
    AJLoader,
  },
  setup() {
    const { territories, getTerritories, deleteTerritory } = useTerritories();
    const store = useStore();
    let loading = ref<boolean>(true);

    let isEditMode = ref<boolean>(false);

    const showAdvanceFilter = ref<boolean>(false);

    const breadcrumbs = {
      title: "Territories",
      breadcrumbs: ["Territories"],
    };

    const config = store.getters.getConfigurations;
    const permissions = config.result.auth.grantedPermissions;
    let defaultModel = ref({
      territoryCode: "",
      territoryName: "",
      territoryDescription: "",
      id: "",
    }) as unknown as ITerritoryDto;

    const defaultTerritoryFilter = ref({
      filter: null,
      territoryCodeFilter: null,
      territoryNameFilter: null,
      territoryDescriptionFilter: null,
      sorting: null,
      skipCount: 0,
      maxResultCount: 1000,
    } as unknown as TerritoriesFilter);

    let modelValue = ref<ITerritoryDto>(defaultModel);

    const tableHeader = ref([
      {
        name: "Actions",
        key: "actions",
      },
      {
        name: "Territory Code",
        key: "territoryCode",
        sortable: true,
      },
      {
        name: "Territory Name",
        key: "territoryName",
        sortable: true,
      },
      {
        name: "Territory Description",
        key: "territoryDescription",
        sortable: true,
      },
    ]);

    const tableData = ref<Array<IGetTerritoryForViewDto>>([]);

    onMounted(async () => {
      // setCurrentPageBreadcrumbs("Territories", ["Territories header info"]);

      await getData();
    });

    const getData = async () => {
      loading.value = true;

      await getTerritories(
        defaultTerritoryFilter.value as unknown as TerritoriesFilter
      );

      tableData.value = territories.value;

      loading.value = false;
    };

    const deleteHandler = async (id: string) => {
      const res = await DeleteItemService.delete(id as string, deleteTerritory);

      if (res) {
        console.log("deleting...");
        for (let i = 0; i < tableData.value.length; i++) {
          if (tableData.value[i].territory.id === id) {
            tableData.value.splice(i, 1);
          }
        }
      }
    };

    const searchFunc = async () => {
      await getData();
    };

    const setModal = (id: string) => {
      isEditMode.value = true;

      const value = territories.value.find((el) => el.territory.id === id);

      if (value) {
        modelValue.value = value?.territory as ITerritoryDto;
      }
    };

    const resetModel = () => {
      modelValue.value = { ...defaultModel };
    };

    return {
      loading,
      tableData,
      tableHeader,
      modelValue,
      isEditMode,
      defaultTerritoryFilter,
      showAdvanceFilter,
      resetModel,
      deleteHandler,
      setModal,
      searchFunc,
      breadcrumbs,
      permissions,
    };
  },
});
