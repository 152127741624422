import { ref, Ref } from "vue";
import { axiosClient, APP_BASE_URL } from "@/core/services/AxiosClient";
import { TerritoriesFilter } from "@/models";
import {
  TerritoriesServiceProxy,
  IGetTerritoryForViewDto,
  CreateOrEditTerritoryDto,
} from "@/shared/service-proxies/service-proxies";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";

interface UsableTerritories {
  territories: Ref<Array<IGetTerritoryForViewDto>>;
  territory: Ref<IGetTerritoryForViewDto>;
  getTerritories: (filters: TerritoriesFilter) => void;
  getTerritory: (id: string) => void;
  deleteTerritory: (id: string) => Promise<boolean>;
  updateOrCreateTerritory: (
    territory: CreateOrEditTerritoryDto
  ) => Promise<boolean>;
}

const useTerritories = (): UsableTerritories => {
  const territories: Ref<Array<IGetTerritoryForViewDto>> = ref(
    [] as unknown as Array<IGetTerritoryForViewDto>
  );

  const client = new TerritoriesServiceProxy(APP_BASE_URL, axiosClient);

  const territory: Ref<IGetTerritoryForViewDto> = ref(
    null as unknown as IGetTerritoryForViewDto
  );

  const store = useStore();

  const getTerritories = async (filters: TerritoriesFilter) => {
    const [
      filter,
      firstnameFilter,
      lastnameFilter,
      sorting,
      skipCount,
      maxResultCount,
      cancelToken,
    ] = [...Object.values(filters)];

    await client
      .getAll(
        filter,
        firstnameFilter,
        lastnameFilter,
        sorting,
        skipCount,
        maxResultCount,
        cancelToken
      )
      .then((data) => {
        territories.value =
          data.items as unknown as Array<IGetTerritoryForViewDto>;
      })
      .catch((error) => {
        store.commit(Mutations.SET_ERROR, error);
      });
  };

  const getTerritory = async (id: string) => {
    await client
      .getTerritoryForView(id)
      .then((data) => {
        territory.value = data;
      })
      .catch((error) => {
        store.commit(Mutations.SET_ERROR, error);
      });
  };

  const updateOrCreateTerritory = async (
    territory: CreateOrEditTerritoryDto
  ) => {
    const resp = await client
      .createOrEdit(territory)
      .then(() => true)
      .catch((e) => {
        store.commit(Mutations.SET_ERROR, e);
        return false;
      });

    return resp;
  };

  const deleteTerritory = async (id: string): Promise<boolean> => {
    const resp = await client
      .delete(id)
      .then(() => true)
      .catch((error) => {
        store.commit(Mutations.SET_ERROR, error);
        return false;
      });
    return resp;
  };

  return {
    territories,
    territory,
    getTerritories,
    getTerritory,
    deleteTerritory,
    updateOrCreateTerritory,
  };
};

export default useTerritories;
