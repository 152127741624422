
import { defineComponent, ref, watch } from "vue";
import { CreateOrEditTerritoryDto } from "@/shared/service-proxies/service-proxies";

export default defineComponent({
  name: "territory-view-modal",
  props: {
    modelValue: {
      required: false,
      type: () => Object as unknown as CreateOrEditTerritoryDto,
    },
  },
  setup(props) {
    const formRef = ref<null | HTMLFormElement>(null);
    const territoryViewModalRef = ref<null | HTMLElement>(null);

    let formData = ref<CreateOrEditTerritoryDto>();

    watch(
      () => props.modelValue,
      () => {
        formData.value =
          props.modelValue as unknown as CreateOrEditTerritoryDto;
      }
    );

    return {
      formData,
      formRef,
      territoryViewModalRef,
    };
  },
});
